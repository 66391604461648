import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from '../Products/ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ products, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product, i) => (
        <Grid key={i} item xs={12} sm={6} md={3}>
          <ShopProductCard index={i} register={{product}} checkbox/>
        </Grid>
      ))}
    </Grid>
  );
}
